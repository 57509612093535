.ilabel-blue {
    text-align: center;
    color: #3cc6f3;
    font-weight: 500;
    margin-top: 50px;
}
.ibtn-assess a {
    font-family: 'Lemon/Milk';
    text-align: center;
    width: 300px;
    padding: 6px;
}

.dc-types-container {
    display: flex;
    align-items: end;
    font-size: 0.9rem;
    font-weight: 500;
}
.dc-types-container ul {
    font-size: 1.1rem;
    padding: 5px;
    padding-bottom: 0;
    margin: 0;
    padding-left: 25px;
    text-align: left;
}
.dc-types-container li {
    padding: 10px 0;
}
.dc-types-bars-title {
    display: flex;
    flex-direction: column;
    text-align: right;
    white-space: nowrap;
    padding-bottom: 20px;
}
.dc-types-bars-title span {
    padding: 5px 8px;
}
.dc-types {
    display: flex;
    border-collapse: collapse;
    text-align: center;
    overflow-x: scroll;
}
.dc-type {
    border: 1px solid;
    min-width: 210px;
    width: 20%;
}
.dc-type h3 {
    font-size: 1.7rem;
    font-family: 'Lemon/Milk';
    font-weight: 500;
    padding: 0 15px;
    margin-bottom: 15px;
    &:lang(ko) {
        font-weight: 600;
    }
}
.dc-type-icon {
    width: 70%;
    max-width: 150px;
    max-height: 150px;
}
.dc-type-bars {
    padding: 10px 0;
}
.dc-type-bar {
    width: 95%;
}
.dc-type-skills { 
    color: #fff; 
    min-height: 215px;
}
.dt-carefree { border-color: #3cc6f3; }
.dt-carefree h3 { color: #3cc6f3; }
.dt-carefree .dc-type-skills { background-color: #3cc6f3; }
.dt-ordinary { border-color: #6a8ec9; }
.dt-ordinary h3 { color: #6a8ec9; }
.dt-ordinary .dc-type-skills { background-color: #6a8ec9; }
.dt-always-on { border-color: #ed6628; }
.dt-always-on h3 { color: #ed6628; }
.dt-always-on .dc-type-skills { background-color: #ed6628; }
.dt-cautious { border-color: #b7d334; }
.dt-cautious h3 { color: #b7d334; }
.dt-cautious .dc-type-skills { background-color: #b7d334; }
.dt-digilog { border-color: #c91c6e; }
.dt-digilog h3 { color: #c91c6e; }
.dt-digilog .dc-type-skills { background-color: #c91c6e; }
.dc-type-bars-range {
    display: flex;
    justify-content: space-between;
}
.dc-type-bars-range span {
    padding: 0 10px;
}

.full-length-container {
    width: 100%;
    max-width: unset;
    margin: 0;
    padding-top: 75px;
}
.full-length-container .index-section-content {
    max-width: 1140px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.container-gr-shade {
    background-color: #f8f8f8;
}
.isection-assess-sp {
    padding-bottom: 75px;
}
.isection-toolip {
    position: relative;
    font-weight: 500;
    color: #3cc6f3;
}
.tooltip-content {
    display: none;
    position: absolute;
    padding: 2vh;
    background: #fff url(../images/dqindex/dq-citizenship-wheel-small.png) no-repeat 2vh center;
    background-size: 15vh;
    border: 1px solid #ed6628;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #808080;
    top: 40px;
    left: 0;
    min-width: 50vh;
    padding-left: 20vh;

}
.isection-toolip:hover .tooltip-content {
    display: block;
}
.dc-scores-container {
    text-align: center;
    margin-bottom: 2vh;
    padding: 2vh;
}
.dc-country-ddl-container {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 0.7vh 3vh;
}
.dc-country-ddl-container::after {
    content: "\25bc";
    color: #ed6628;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1.75vh;
    pointer-events: none;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 2rem;
}
.dc-country-ddl {
    border-radius: 10px;
    border: 1px solid grey;
    min-width: 30vh;
    padding: 1vh;
    color: grey;
    font-size: 1.7rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.dc-national-score {
    background-color: #808080;
}
.dc-global-score {
    background-color: #edd848;
}
.dc-score-content {
    display: inline-block;
    font-family: 'Lemon/Milk';
    font-size: 6vh;
    width: 16vh;
    height: 15vh;
    color: #fff;
    border-radius: 1.5vh;
    margin: 0.5vh;
    padding-top: 5vh;
    line-height: 2.5vh;
}
.dc-score-content span {
    font-size: 1.7vh;
}
.dq-dc-chart {
    width: 90%;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    min-height: 10vh;
    margin: 0 auto;
    border-radius: 5px;
}
.dc-chart-content {
    margin: 15px;
    margin-left: 50px;
    position: relative;
}
.dc-chart-vlegend {
    position: absolute;
    transform: rotate(270deg);
    color: #2d4b62;
    top: 45%;
    left: -85px;

    &:lang(ko) {
        left: -65px;
    }
}
.dc-chart-name {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 10px;
    color: #2d4b62;
}
.isection-assessments {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
}
.isection-assessments div {
    display: block;
    position: relative;
    padding: 0 5%;
    padding-top: 10px;
    margin-bottom: 10px;
    width: 33%;
    text-align: center;
}
.isection-assessments img {
    width: 65px;
}
.isection-assessments span {
    display: block;
    color: #3cc6f3;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 15px;
}
.iassess-details {
    display: flex;
    padding-top: 8vh;
}
.iassess-details-ico, .iassess-details-content {
    display: block;
}
.iassess-details-ico {
    padding-top: 35px;
    text-align: center;
    width: 40%;
}
.iassess-details-content {
    width: 60%;
}
.iassess-details-ico img {
    width: 75%;
}
.iaction-kits-content {
    width: 88%;
    margin: 0 auto;
}
.iaction-kits-col {
    padding: 0 10px;
    padding-top: 10px;
    width: 25%;
    margin: 5px;
    border: 1px solid #6e6f70;
    border-radius: 6px;
    position: relative;
    padding-bottom: 80px;
}
.iaction-kits-col p {
    text-align: left;
    margin-top: 1px;
}
.iaction-kits-col img {
    height: 70px;
    width: auto;
}
.iaction-col .iaction-btn {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}
.iaction-kits-col .iaction-btn a {
    width: 90%;
}

@media only screen and (max-width: 768px) {
    .isection-assess-sp {
        padding-bottom: 25px;
    }
    .full-length-container {
        padding-top: 50px;
    }
    .isection-assessments, .iassess-details {
        display: block;
    }
    .isection-assessments div, .iassess-details div, .iaction-kits-col {
        width: 100%;
    }
    .iaction-kits-col .iaction-btn {
        position: relative;
    }
    .iaction-kits-col .iaction-btn a {
        margin-bottom: 25px;
    }
    .iaction-kits-col {
        padding-bottom: 0;
    }
    .tooltip-content {
        min-width: 37vh;
        margin: 0 auto;
        padding-top: 18vh;
        padding-left: 2vh;
        background: #fff url(/static/media/dq-citizenship-wheel-small.74ab46ac.png) no-repeat center 2vh;
        background-size: 15vh;
        top: 25px;
        left: -7vh;
    }
    .dc-chart-content {
        margin-left: 30px;
    }
    .dc-chart-name {
        font-size: .9rem;
    }
    .dc-chart-vlegend {
        font-size: .7rem;
        left: -55px;
        
        &:lang(ko) {
            left: -40px;
        }
    }
}