.setting-container {
    padding: 56px 20px;
    min-height: 100%;
    width: 100%;
}

.dq-audience-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 10px 20px;
    margin: auto;
    width: 80vw;
    max-width: 700px;
    font-family: 'Source Sans Pro';
}

.post-quiz-section-dq-image {
    text-align: center;
    padding-top: 5vh;
}

.post-quiz-sub-title {
    font-size: 1.2rem !important;
    color: gray !important;
}

.post-quiz-label {
    font-style: italic;
    text-align: center;
    color: gray !important;
}

.audience-section-dq-image {
    text-align: center;
    padding-top: 9vh;
}

.audience-section-dq-image img, .post-quiz-section-dq-image img {
    height: 12vh;
    margin: 1vh auto;
}

.dq-audience-title {
    color: #19174a;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 2vh;
    margin-bottom: 0;
}

.dq-audience-options {
    display: table;
    border-spacing: 3vh;
    width: 100%;
}

.dq-audience-options span {
    border: 2px solid #1fb2e7;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 2.5vh;
    min-width: 30%;
    border-radius: .6rem;
    text-align: center;
    color: gray;
    white-space: pre-wrap;
    display: table-cell;
    vertical-align: middle;
}

.dq-audience-options .selected {
    color: #fff;
    background-color: #1fb2e7;
}

.dq-audience-login {
    margin-top: 3vh;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.dq-audience-login p {
    margin: 5px;
    color: gray;
}

.dq-audience-login a {
    padding: 5px 15px;
    color: #f9631c;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-dashboard-modal-login a {
    color: #f9631c;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-audience-login-blue {
    margin-top: 4vh;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}
.dq-audience-login-blue a {
    padding: 5px 15px;
    color: #1fb2e7;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.dq-options-submit {
    position: relative;
    margin-top: 6vh;
    text-align: center;
}

.dq-options-submit button {
    border: 2px solid #1fb2e7;
    border-radius: 10px;
    padding: 1.5vh 9vh;
    margin: auto;
    color: #1fb2e7;
    font-size: 1.6rem;
    background-color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.dq-audience-container .dq-options-submit button {
    padding: 1.5vh 4vh;
}

.dq-btn-2 button {
    margin-bottom: 20px;
    width: 30vh;
    padding: 1.5vh 0;
    display: block;
}

.dq-app-footer {
    clear: both;
    width: 100%;
    font-family: 'Source Sans Pro';
}

.dq-guest-footer, .dq-app-footer {
    /* position: absolute;
    width: 100%;
    bottom: 3%;
    left: 0; */
    margin-top: 5vh;
    text-align: center;
}

.dq-dashboard-modal-login-footer{
    /* position: absolute;
    width: 100%;
    bottom: 3%;
    left: 0; */

    text-align: center;
}

.dq-footer-link {
    color: #1fb2e7;
    font-size: .8rem;
    font-weight: 500;
    margin: .8vh;
}

.dq-modal-contact-us-link {
    color: #1fb2e7;
    font-size: .8rem;
    font-weight: 500;
}

.dq-guest-footer span, .dq-app-footer span {
    color: #1fb2e7;
    font-size: .8rem;
}

.dq-copyright {
    margin-top: 1vh;
    text-align: center;
    font-size: .75rem;
    color: gray;
}

@media only screen and (max-width: 768px) {
    .dq-audience-title {
        font-size: 1.75rem;
    }

    .dq-audience-options {
        border-spacing: 0;
    }

    .dq-audience-options span {
        display: block;
        white-space: unset;
        padding: 1.5vh;
        margin: 2vh 0;
        font-size: 1rem;
    }
}