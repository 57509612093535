.dqindex-footer {
    position: relative;
    background-color: #2DB3E5;
    margin-top: 30px;
}
.ifooter-content {
    display: flex;
    position: relative;
    max-width: 1140px;
    color: #fff;
    margin: 0 auto;
    padding: 60px 20px 65px;
    text-align: left;
    line-height: 2rem;
    font-size: .9rem;
}
.ifooter-col a {
    text-decoration: none;
    color: #fff;
}
.ifooter-col .fa {
    color: #2DB3E5;
}
.ifooter-copyright {
    width: 50%;
}
.ifooter-copyright span {
    padding: 0 5px;
}
.ifooter-email, .ifooter-social-media {
    width: 25%;
}
.ifooter-social-media a {
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: -webkit-baseline-middle;
    height: 30px;
    font-size: 1.05rem;
    margin: 7px 7px 0 0;
}
.ifooter-title {
    font-weight: bold;
    font-size: 1.2rem;
}
.ifooter-subtitle {
    font-weight: bold;
}
.ifooter-gdpr {
    line-height: 1rem;
    padding: 5px 0 10px;
}
.ifooter-gdpr a {
    display: inline-block;
    text-decoration: underline;
}
.language {
    position: relative;
    display: inline-block;
}
.language .fa {
    color: #fff;
}
.language span {
    font-weight: 500;
    cursor: pointer;
}
.language:hover .locales {
    visibility: visible;
    background-color: #fff;
    transition: background-color 2s;
}
.language .locales {
    visibility: hidden;
    position: absolute;
    bottom: 30px;
    background-color: #2DB3E5;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 6px 20px;
    min-width: 170px;
    border-radius: 8px;
}
.language .locales span {
    text-transform: capitalize;
    border-bottom: 1px solid #ccc;
}
.language .locales span:last-child {
    border-bottom: 0;
}
.dqindex-cookies {
    display: none;
    position: fixed;
    padding: 0 15px;
    bottom: 15px;
    width: 100%;
    z-index: 2;
}
.icookies-content {
    position: relative;
    background-color: #2d4b62;
    color: #fff;
    border-radius: 15px;
    padding: 40px;
    margin: 0 auto;
}
.icookies-content a {
    color: #fff;
    font-weight: 500;
}
.icookies-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #2DB3E5;
    padding: 0 3px;
    font-size: 1.1rem;
}
.icookies-close:hover {
    color: #fff;
}
.icookies-show {
    display: block !important;
}

@media only screen and (max-width: 768px) {
    .ifooter-content {
        display: block;
    }
    .ifooter-col {
        width: 100%;
    }
    .icookies-content {
        padding: 30px;
    }
}