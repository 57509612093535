// -- vars
$default-size: 1em;
$label-font-size: $default-size / 3.3;
$label-font-size-redo: $default-size * 4.7;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

.set-size {
  font-size: 10em;
}

.bar-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin draw-progress-to-50($progress, $color: #3cc6f3) {
	.pie {
	    .half-circle { border-color: $color; }
	    .left-side { transform: rotate($progress * 3.6deg); }
	    .right-side { display: none; }
	}
}

@mixin draw-progress-from-51($progress, $color: #3cc6f3) {
  .pie {
  	clip: rect(auto, auto, auto, auto);
  	
    .half-circle { border-color: $color; }
    .left-side { transform: rotate($progress * 3.6deg); }
	  .right-side { transform: rotate(180deg); }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}

.score-label {
  font-size: .35em;
  line-height: 2em;
}
.score-label-pt {
  font-size: .5em;
  vertical-align: super;
}

.pie-wrapper {
  height: 1em;
  width: 1em;
  margin: 0 auto;
  position: relative;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      height: 100%;
      width: 100%;
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    border-radius: 50%;
    color: #fff;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    text-align: center;
    // line-height: $label-font-size-redo * .70;
    position: absolute;
    // top: -$label-font-size-redo / 25;
    // right: $label-font-size-redo / 20;
    // bottom: $label-font-size-redo / 20;
    // left: $label-font-size-redo / 20;
    top: $label-font-size-redo * .2;
    right: $label-font-size-redo / 20;
    bottom: $label-font-size-redo / 20;
    left: $label-font-size-redo / 20;

    .smaller {
      color: #fff;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .label.smaller {
    font-size: $label-font-size / 1.7;
    top: $label-font-size-redo * .33;
  }

  .pie-border:before {
  	content: "";
    height: 100%;
    width: 100%;
    border: $default-size / 50 solid #3cc6f3;
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  .pie-border:after {
  	content: "";
    height: 86%;
    width: 86%;
    border: $default-size / 50 solid #3cc6f3;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 7%;
    left: 7%;
  }

  &.style-2 {
    .label { color: #3cc6f3; }
    .smaller { color: #3cc6f3; }
  }

  &.progress-0 { @include draw-progress-to-50(0); }
  &.progress-5 { @include draw-progress-to-50(5); }
  &.progress-10 { @include draw-progress-to-50(10); }
  &.progress-15 { @include draw-progress-to-50(15); }
  &.progress-20 { @include draw-progress-to-50(20); }
  &.progress-25 { @include draw-progress-to-50(25); }
  &.progress-30 { @include draw-progress-to-50(30); }
  &.progress-35 { @include draw-progress-to-50(35); }
  &.progress-40 { @include draw-progress-to-50(40); }
  &.progress-45 { @include draw-progress-to-50(45); }
  &.progress-50 { @include draw-progress-to-50(50); }
  
  &.progress-55 { @include draw-progress-from-51(55); }
  &.progress-60 { @include draw-progress-from-51(60); }
  &.progress-65 { @include draw-progress-from-51(65); }
  &.progress-70 { @include draw-progress-from-51(70); }
  &.progress-75 { @include draw-progress-from-51(75); }
  &.progress-80 { @include draw-progress-from-51(80); }
  &.progress-85 { @include draw-progress-from-51(85); }
  &.progress-90 { @include draw-progress-from-51(90); }
  &.progress-95 { @include draw-progress-from-51(95); }
  &.progress-96 { @include draw-progress-from-51(96); }
  &.progress-97 { @include draw-progress-from-51(97); }
  &.progress-98 { @include draw-progress-from-51(98); }
  &.progress-99 { @include draw-progress-from-51(99); }
  &.progress-100 { @include draw-progress-from-51(100); }
  
}

