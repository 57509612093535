.pass-reset-container {
    padding-top: 10vh;
    margin: 0 auto;
    min-height: 100%;
    max-width: 400px;
    text-align: center;
    font-family: 'Source Sans Pro';
}

.pass-reset-content {
    margin: 2vh;
}

.pass-reset-result {
    color: #0cc0e0;
    font-size: 2.4rem;
    font-family: 'Lemon/Milk';
}

.pass-reset-error {
    color: maroon;
    font-size: 2.4rem;
    font-family: 'Lemon/Milk';
}

.pass-reset-title {
    color: gray;
}

.pass-reset-input {
    width: 37vh;
    min-width: 250px;
    max-width: 325px;
    margin: 0 auto;
}

.pass-reset-input label {
    display: block;
    color: #1fb2e7;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1vh;
    padding-left: .75vh;
    text-align: left;
}

.pass-reset-input input{
    border-radius: 10px;
    border: 1px solid gray;
    width: 100%;
    padding: .9vh;
    color: gray;
    font-size: 1rem;
}

.pass-reset-submit {
    position: relative;
    margin-top: 4vh;
    text-align: center;
}

.pass-reset-submit button {
    border: 2px solid #1fb2e7;
    border-radius: 10px;
    padding: 1.5vh 9vh;
    margin: auto;
    color: #1fb2e7;
    font-size: 1.6rem;
    background-color: #fff;
    font-weight: 600;
    cursor: pointer;
}
