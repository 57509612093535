
.home-container {
    min-height: 100%;
    width: 100%;
}


.home-list-item-main {
    padding: 10px;
    border-left: 11px solid;
}

.home-list-item-main-lock {
    padding: 10px;
    border-left: 11px solid;
    opacity: 0.5;
}

.home-list-item-title {
    margin-left: 16px;
    /* margin-top: -8px; */
    /* width: 50vw; */
}

.home-list-item-title span {
    font-size: 0.55rem;
    padding: 2px 8px;
    color: #3cc6f3;
    border: 1px solid #3cc6f3;
    border-radius: 12px;
}

.home-list-item-title p {
    margin: 0;
    margin-top: .25rem;
    font-size: 0.9rem;
    line-height: 1.1rem;
    white-space: pre-wrap;
}

.home-list-item-img {
    height: 55px;
    width: 55px;
    margin: auto 0;
}

.home-list-item-container {
    background: transparent;
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
}

.home-list-item-title-section {
    /*background: azure;*/
    /*border: 1px dotted azure;*/
    height: auto;
    display: flex;
    width: auto;
}

.home-list-item-progress-section {
    font-size: 3.5em;
    padding: 5px;
    width: 19%;
    text-align: center
}

.home-list-item-progress-section p {
    color: #f8b143;
    padding: 6px 8px;
    font-size: 0.9rem;
}

.home-top-section-dq-image {
    height: 70px;
    margin: 7px 0;
}

.home-top-section-container {
    /*display: flex;
    justify-content: space-around;
    overflow: hidden;*/
    text-align: center;
}

.home-top-section-full {
    min-height: 240px;
    transition: height 0.6s ease-out;

    width: 50%;
    height: 100%;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: fixed;
}

.home-badges-section-full {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    z-index: 50;

    display: flex;
    /* justify-content: center; */
    flex-flow: column;
    background-color: #eee;
}

.home-top-section-partial {
    height: 50px;
    transition: height 0.6s ease-out;
}
.dq-score span:first-child {
    font-size: 70px;
    line-height: 70px;
}
.dq-score span {
    display: inline-block;
    font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
    color: #19174a;
}
.dq-score span:last-child {
    vertical-align: top;
    font-size: 40px;
    line-height: 50px;
}
.v-stats-btn {
    background-color: #1a194c;
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    padding: 2px;
    margin: 3px auto 5px;
    width: 25%;

    display: none;
}
.raz-chat img {
    height: 100px;
}



.home-item-detail-container {
    background-color: white;
    height: auto;
    left: 0;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.home-item-detail-close {
    max-height: 0;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
}

.home-item-detail-open {
    /* max-height: 600px; */
    transition: max-height 0.8s ease-out;
    border-left: 11px solid;
}

.home-item-detail-header-section {
    background-color: #e7e5e5;
    box-shadow: inset 0 10px 6px -6px #777;
    padding: 15px 20px;
    margin: 0 auto;
    display: table;
    width: 100%;
}

.home-item-detail-header-score {
    background-color: #454545;
    border-radius: 9px;
    text-align: center;
    padding: 7px 5px;
    min-width: 90px;
    color: #fff;
    display: table-cell;
    vertical-align: middle;
}

.home-item-detail-header-title {
    font-size: .9em;
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-weight: 500;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 8px 0 8px;
    width: 100%;
    color: #19174a;
}

.home-item-detail-header-title div {
    background-color: #fff;
    border: 1px dashed #454545;
    border-radius: 8px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
}

.home-item-detail-header-score span {
    font-size: 1.4em;
}

.header-score-pt {
    font-size: .7em !important;
    vertical-align: super;

}

.score-group {
    color: #ed6628;
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-size: .8em;
    text-transform: capitalize;
}

.home-item-detail-header-score-desc {
    font-size: .7em;
}

.home-item-detail-charts-section {
    width: 100%;
    padding: 4% 2%;
    height: auto;
    align-self: flex-start;
}

.home-item-detail-btn-section {
    display: table;
    font-size: 1em;
    width: 85%;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
    margin-bottom: 10px;
}

.chart-title {
    font-size: .72em;
}

.chart-title span {
    color: gray !important;
}

.chart-subtitle {
    color: #ed6628;
    font-size: .72em;
}

.chart-legend {
    display: table;
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-size: 10px;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin: 5px auto;
    padding: 4px 8px 4px 0px;
}

.chart-legend span {
	display: table-cell;
	padding-left: 10px;
}

.chart-my-score {
	color: #3cc6f3;
}
.chart-national-score {
	color: #ed1a3d;
}
.chart-global-score {
	color: gray;
}

.chart-my-score::before, .chart-national-score::before, .chart-global-score::before {
	content: "•";
    font-size: 40px;
    vertical-align: middle;
    line-height: 10px;
    margin-top: -2px;
    display: inline-block;
    padding-right: 1px;
}

.home-item-detail-chart {
    
}

.home-item-detail-chart img {
    padding: 2px 5px 11px;
    width: 90%;
}

.chart-x-axis-buttons {
	display: table;
	width: 90%;
	margin: 0 auto;
}
.chart-x-axis-buttons span {
	display: table-cell;
	font-size: 10px;
}
.chart-x-axis-buttons label {
	background-color: #eee;
	border-radius: 50%;
	color: gray;
	padding: 5px 7px;
	cursor: pointer;
}
.chart-x-axis-buttons .active {
	color: #fff;
	background-color: #1a194c;
}

.home-item-detail-t-btn {
    display: table-cell;
    vertical-align: bottom;
    
    display: block;
    width: 65%;
    margin: 0 auto;
    max-width: 35vh;
}
.home-item-detail-t-btn button {
    margin: 0;
}

@media only screen and (max-width: 768px) {

    .home-top-section-full, .home-badges-section-full {
        position: unset;
        width: auto;
    }

    .home-list-item-main {
        border-left: 7px solid;
    }

    .home-item-detail-open {
        border-left: none;
    }

}