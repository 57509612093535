.login-container {
    text-align: center;
    min-height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro';
}

.login-container .dq-logo-image {
    text-align: center;
}

.login-container .dq-logo-image img {
    height: 15vh;
    margin: 1vh auto;
}

.dq-login-content {
    text-align: center;
    margin: 2vh;
}
.dq-login-txt {
    padding: 1vh;
    margin: .5vh;
    text-align: center;
    font-size: 1rem;;
    color: gray;
    border-radius: 10px;
    border: 1px solid gray;
    min-width: 250px;
}

.login-container .dq-options-submit {
    margin-top: 2vh;
}

.login-container .dq-options-submit button {
    min-width: 250px;
}

.login-container .forgot-password {
    margin-top: 1vh;
}

.login-container .forgot-password a {
    color: #6495EF;
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
