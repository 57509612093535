.quiz-result-container {
    padding: 30px 20px 0;
    min-height: 100%;
    width: 100%;
    text-align: center;
}
.quiz-result-content {
    text-align: center;
}
.quiz-result-content h1 {
    font-size: 2em;
    margin-top: 4vh;
}
.score-spinner {
    font-size: 3.8em;
    background: url(../images/dq-spinner.png) no-repeat center center;
    background-size: contain;
    padding: 10%;
    min-height: 24vh;
    line-height: 16vh;
}
.score-desc {
    font-size: 1.3em;
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-weight: bold;
    padding: .5em 1em;
}

.lb-color {
    color: #3cc6f3;
}
.bavg-color {
    color: #fa71fb;
}
.gr-color {
    color: gray;
}
.avg-color {
    color: #f5a335;
}

.quiz-score-container {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
}

.quiz-score-content {
    padding: 3vh 6vh;
}

.score-rubric {
    
}
.rubric-container {
    display: flex;
    font-family: "Source Sans Pro", "Helvetica Neue";
    background-color: #f4f2f3;
    border-radius: 15px;
    padding: 10px 16px;
    width: 75vh;
    display: none;
}
.rubric-scores {
    display: flex;
    text-align: right;
    padding-right: 10px;
    flex-direction: column;    
    justify-content: center;
    font-family: 'Lemon/Milk';
    font-size: 1.3rem;
}
.rubric-scores span {
    padding: 10px 0;
}
.rubric-content {
    text-align: left;
    width: 100%;
}
.rubric-row {
    display: flex;
    padding: 5px 10px;
    border-left: 12px solid;
    margin-bottom: 2px;
}
.rubric-row span { 
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: .9rem;
    width: 30%;
}
.rubric-row div {
    text-align: justify;
    font-size: .7rem;
    width: 100%;
}
.rubric-excellent { border-color: #3cc6f3; }
.rubric-above-avg { border-color: #f5a335; }
.rubric-below-avg { border-color: #838383; }
.rubric-req-att { border-color: #ff00c6; }
.rubric-excellent span, .rubric-scores span:first-child { color: #3cc6f3; }
.rubric-above-avg span, .rubric-scores span:nth-child(2) { color: #f5a335; }
.rubric-below-avg span { color: #838383; }
.rubric-req-att span, .rubric-scores span:last-child { color: #ff00c6; }

.score-rubric img {
    min-height: 180px;
    height: 33vh;
    width: 70vh;
}

.dq-result-button-container {
    display: flex;
    width: 115vh;
    margin: 0 auto;
    margin-top: 3vh;
    align-items: center;
}

.dq-more-info-link {
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-weight: 500;
    width: 100vh;
    font-size: 1.2rem;
}

.dq-more-info-link a {
    color: #ee6629;
    font-style: normal;
}

.quiz-result-container .border-btn-ui {
    border-style: solid !important;
    width: 20vh;
    padding: 1.5vh;
}

.dq-disclaimer {
    width: 70vh;
    padding: 1vh 0 1vh;
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-size: .7rem;
    font-style: italic;
    color: gray;
}

.dq-disclaimer a {
    color: #1fb2e7;
}

.rubric-desktop, .dq-disc-desktop {
    display: block;
}

.rubric-mobile, .dq-disc-mobile {
    display: none;
}

.dq-dc-type {
    font-size: 3.8em;
    padding: 10%;
    margin-top: 3vh;
    min-width: 25vh;
    min-height: 25vh;
    line-height: 16vh;
}
.dc-type-container .C1 { background: url(../images/dc-types/dc-type-c1.png) no-repeat center center; background-size: contain; }
.dc-type-container .C2 { background: url(../images/dc-types/dc-type-c2.png) no-repeat center center; background-size: contain; }
.dc-type-container .C3 { background: url(../images/dc-types/dc-type-c3.png) no-repeat center center; background-size: contain; }
.dc-type-container .C4 { background: url(../images/dc-types/dc-type-c4.png) no-repeat center center; background-size: contain; }
.dc-type-container .C5 { background: url(../images/dc-types/dc-type-c5.png) no-repeat center center; background-size: contain; }

.dc-type-desc {
    text-align: left !important;
    color: #19174a;
}

@media only screen and (max-width: 768px) {
    .quiz-score-container {
        display: block;
        margin-top: 0;
    }

    .quiz-result-content h1 {
        font-size: 1.5em;
    }

    .quiz-score-content {
        width: 100%;
        padding: 0;
    }

    .score-spinner {
        font-size: 2.5em;
        min-height: 20vh;
        line-height: 10vh;
    }

    .score-desc {
        font-size: 1.1em;
        margin-bottom: 1vh;
    }

    .quiz-result-container .border-btn-ui {
        width: 27vh;
        padding: 2vh;
    }

    .score-rubric {
        width: 100%;
    }

    .score-rubric img {
        width: 100%;
        height: auto;
    }

    .rubric-desktop, .dq-disc-desktop {
        display: none;
    }

    .rubric-mobile, .dq-disc-mobile {
        display: block;
    }

    .rubric-container {
        padding: 8px 10px;
        width: 43vh;
    }
    .rubric-scores {
        font-size: 1.1rem;
    }
    .rubric-row span {
        font-size: .7rem;
        width: 38%;
    }
    .rubric-row div {
        font-size: .6rem;
    }

    .dq-result-button-container {
        display: block;
        margin-top: 1vh;
    }

    .dq-more-info-link {
        font-size: 1rem;
    }

    .dq-result-button-container .border-btn-ui {
        margin: 2vh 0 1vh;
    }

    .dq-result-button-container, .dq-more-info-link, .dq-disclaimer {
        width: 100%;
    }

    .dc-type-desc {
        margin: 0 3vh 6vh !important;
    }
}
