.index-page-container {
    font-family: "Source Sans Pro", "Helvetica Neue";
    user-select: text;
}
.index-header {
    position: fixed;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 10px 0px;
    top: 0px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 8px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    height: 8.5vh;
    min-height: 70px;
    width: 100%;
    z-index: 9;
}
.iheader-contnet {
    display: flex;
    position: relative;
    padding: 0 4vw;
    height: 100%;
}
.index-logo {
    display: flex;
    align-items: flex-end;
}
.index-logo img {
    width: 130px;
}
.index-menu {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: flex-end;
    font-family: 'Lemon/Milk';
}
.imenu-item {
    padding: 0 20px;
}
.imenu-item a {
    text-decoration: none;
    color: #6e6f70;
}
.imenu-item-active, .imenu-item a:hover {
    color: #ed6628 !important;
}
.imenu-link {
    border: 1px solid;
    padding: 4px 7px;
    border-radius: 6px;
    color: #19174a !important;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.imenu-language {
    border-radius: 10px;
    border: 1px solid gray;
    width: 100%;
    padding: .9vh;
    color: gray;
    font-size: 1rem;
    margin-top: .9vh;
    margin-bottom: .8vh;
}

.dq-lang-select {
    position: relative;
}
.dq-lang-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 1vh 5vh 1vh 4vh;
}

.dq-lang-select::after {
    content: "\25bc";
    color: grey;
    position: absolute;
    top: 0;
    right: 0;
    padding:  1vh;
    pointer-events: none;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 2vh;
}

.index-banner {
    position: relative;
    background: #1c3f53 url(../images/dqindex/world-map-hr.png) no-repeat center center;
    background-size: contain;
    color: #fff;
    margin-top: 70px;
}
.index-banner2 {
    background: #103045 url(../images/dqindex/dqassessment-banner.png) no-repeat center center;
    background-size: contain;
}
.ibanner-img {
    visibility: hidden;
    width: 100%;
    /* width: calc(100% - 80px); */
}
.ibanner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.ibanner-heading {
    position: absolute;
    width: 25%;
    left: 4vw;
    top: 40%;
    &:lang(ko) {
        width: 21%;
    }
}
.ibanner-heading-assess {
    position: absolute;
    width: 25%;
    right: 4vw;
    top: 40%;
    &:lang(ko) {
        top: 25%;
    }
}
.ibanner-heading-assess a {
    text-decoration: none;
    font-family: 'Lemon/Milk';
    font-size: 1.5vw;
    padding: 0.5vw 0;
    border: 1px solid #3cc6f3;
    border-radius: 0.5vw;
    margin-top: 1vw;
    display: block;
    color: #eee921;
    text-align: center;
    width: 93%;
}
.ibanner-heading-assess a:hover {
    border-color: #eee921;
}
.ihead-title {
    font-family: 'Lemon/Milk';
    font-weight: normal;
    font-size: 3vw;
    margin: 0;
    &:lang(ko) {
        font-size: 3.95vw;
    }
}
.ihead-subtitle {
    color: #3cc6f3;
    font-size: 1.55vw;
    margin: 0;
}
.press-release-btn {
    position: absolute;
    top: 10%;
    right: 7%;
}
.press-release-btn a {
    display: block;
    background-color: #3cc6f3;
    text-decoration: none;
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.5vw;
    font-weight: 500;
}
.press-release-btn a:hover {
    background-color: #0cc0e0;
}
.ibanner-sm-txt {
    position: absolute;
    font-style: italic;
    font-size: .8vw;
    padding: 0 10px;
    bottom: 5%;
    left: 55%;
}


.index-section-container {
    margin: 0 auto;
    margin-top: 75px;
    max-width: 1140px;
    padding: 0 15px;
}
.isc-full-length {
    max-width: 1380px;
}
.isection-title {
    text-align: center;
    color: #ed6628;
    font-size: 2.2rem;
}
.isection-content {
    color: #808080;
    font-size: 1.65rem;
    text-align: justify;
}
.isection-content a {
    color: #3cc6f3;
    font-weight: 500;
}
.isection-content img {
    max-width: 100%;
}

.skills-tracker {
    padding: 80px 0 60px;
}

.index-actions {
    padding-top: 30px;
}
.iactions-content {
    display: flex;
}
.iaction-col {
    display: block;
    position: relative;
    text-align: center;
}
.iaction-wizard {
    padding-top: 50px;
    width: 33%;
}
.iaction-wizard-content {
    margin: 0 30px;
    padding-bottom: 125px;
}
.iaction-wizard:after {
    content: "";
    height: 7px;
    background-color: #3cc6f3;
    position: absolute;
    top: -1px;
}
.iaction-wizard:before {
    content: '';
    position: absolute;
    top: -20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%);
    left: 50%;
    background: #e6e7e9;
    border: 2px solid #3cc6f3;
}
.iaction-col h3, .iaction-col h4 {
    font-size: 1.5rem;
    margin: 0;
}
.iaction-col h3 {
    color: #3cc6f3;
}
.iaction-col h4 {
    color: #ed6628;
    min-height: 66px;
    display: flex;
    justify-content: center;
    &:lang(ko) {
        align-items: center;
    }
}
.iaction-col p {
    font-size: 1.1rem;
    color: #808080;
}
.iaction-col img {
    width: 95%;
    margin: 0 auto;
}
.iaction-dct:before {
    background: #3cc6f3;
}
.iaction-dct:after {
    width: 50%;
    right: 0;
}
.iaction-co:after {
    width: 100%;
    left: 0;
}
.iaction-conf:after {
    width: 50%;
    left: 0;
}

.iaction-dct img {
    width: 50%;
    margin-top: 50px;
}
.iaction-co img {
    margin-top: 50px;
}
.iaction-btn a {
    padding: 5px;
    color: #3cc6f3;
    border: 2px solid #3cc6f3;
    border-radius: 5px;
    text-decoration: none;
    width: 240px;
    margin: 25px auto;
    display: block;
    font-weight: 400;
    font-size: 1.5rem;

}
.iaction-btn a:hover {
    color: #fff;
    background: #3cc6f3;
}

/*.digi-skills-row {
    display: flex;
    padding: 36px 0;
    justify-content: center;
}
.digi-skill {
    height: 150px;
    width: 250px;
    position: relative;
    margin: 0 9px;
    font-size: 1.3rem;
    color: #fff;
  }
  .digi-skill-content {
    position: absolute;
    text-align: center;
    padding: 70px 30px 0;
    top: -1px;
    z-index: 1;
  }
  .digi-skill-content span {
      font-family: 'Lemon/Milk';
  }
  .digi-skill::before, .digi-skill::after {
    content: "";
    position: absolute;
    border-left: 125px solid transparent;
    border-right: 125px solid transparent; 
  }
  .digi-skill::before {
    bottom: -58px;
    border-top: 58px solid; 
  }
  .digi-skill::after {
    top: -58px;
    border-bottom: 58px solid; 
  }

  .dl-cos { background: #3cc6f3; }
  .dl-cos::before { border-top-color: #3cc6f3; }
  .dl-cos::after { border-bottom-color: #3cc6f3; }
  .dl-cos .digi-skill-content {
      background: url(../images/dqindex/icons/child-online-safety-icon.png) top center no-repeat;
      background-size: 55px;
  }

  .dl-learn { background: #f8b143; }
  .dl-learn::before { border-top-color: #f8b143; }
  .dl-learn::after { border-bottom-color: #f8b143; }
  .dl-learn .digi-skill-content {
    background: url(../images/dqindex/icons/learning-icon.png) top center no-repeat;
    background-size: 94px;
}

  .dl-or { background: #b7d334; }
  .dl-or::before { border-top-color: #b7d334; }
  .dl-or::after { border-bottom-color: #b7d334; }
  .dl-or .digi-skill-content {
    background: url(../images/dqindex/icons/participation-icon.png) top center no-repeat;
    background-size: 62px;
}

  .dl-dit { background: #fff; color: #6e6f70; font-weight: bold; font-size: 1.6rem; }
  .dl-dit::before { border-top-color: #fff; }
  .dl-dit::after { border-bottom-color: #fff; }
  .dl-dit .digi-skill-content {
    background: url(../images/dqindex/dqindex-logo.png) center 18px no-repeat;
    background-size: 160px;
}

  .dl-wi { background: #6a8ec9; }
  .dl-wi::before { border-top-color: #6a8ec9; }
  .dl-wi::after { border-bottom-color: #6a8ec9; }
  .dl-wi .digi-skill-content {
    background: url(../images/dqindex/icons/digital-infra-icon.png) top center no-repeat;
    background-size: 55px;
}

  .dl-wb { background: #c91c6e; }
  .dl-wb::before { border-top-color: #c91c6e; }
  .dl-wb::after { border-bottom-color: #c91c6e; }
  .dl-wb .digi-skill-content {
    background: url(../images/dqindex/icons/wellbeing-icon.png) top center no-repeat;
    background-size: 71px;
}

  .dl-fe { background: #ea4e99; }
  .dl-fe::before { border-top-color: #ea4e99; }
  .dl-fe::after { border-bottom-color: #ea4e99; }
  .dl-fe .digi-skill-content {
    background: url(../images/dqindex/icons/female-icon.png) top center no-repeat;
    background-size: 45px;
}*/

.digi-skills-row {
    display: flex;
    padding: 3.1vw 0;
    justify-content: center;
}
.digi-skill {
    height: 8vw;/*116px; 148 */ 
    width: 16vw; /*200px; 240 */
    position: relative;
    margin: 0 .7vw;
    font-size: 1.3vw;
    color: #fff;
    opacity: .6;
    border-radius: 0.2vw;
  }
.digi-skill:hover {
    opacity: 1;
}
.digi-skill-content {
    position: absolute;
    text-align: center;
    padding: 4.5vw 2.2vw 0;
    top: -.7vw;
    z-index: 1;
}
.digi-skill-content span {
    font-family: 'Lemon/Milk';
}
.digi-skill-hover {
    display: none;
    position: absolute;
    color: #888;
    z-index: 2;
    top: 5%;
    left: 80%;
    min-width: 20vw;
    text-align: left;
    background: #fff;
    padding: 15px;
    border: 1px solid;
    border-radius: 10px;
}
.hover-left {
    right: 80%;
    left: auto;
}
.digi-skill-hover div {
    margin-top: 3px;
}
.digi-skill-hover img {
    width: 50px;
    margin: 5px 3px;
}
.dl-cos:hover .digi-skill-hover, .dl-learn:hover .digi-skill-hover, .dl-or:hover .digi-skill-hover, 
.dl-wi:hover .digi-skill-hover, .dl-wb:hover .digi-skill-hover, .dl-fe:hover .digi-skill-hover {
    display: block;
}

.dl-cos .digi-skill-hover { border-color: #3cc6f3; }
.dl-learn .digi-skill-hover { border-color: #f8b143; }
.dl-or .digi-skill-hover { border-color: #b7d334; }
.dl-wi .digi-skill-hover { border-color: #6a8ec9; }
.dl-wb .digi-skill-hover { border-color: #c91c6e; }
.dl-fe .digi-skill-hover { border-color: #ea4e99; }

.digi-skill::before, .digi-skill::after {
    content: "";
    position: absolute;
    border-left: 8vw solid transparent;
    border-right: 8vw solid transparent; 
}
.digi-skill::before {
    bottom: -4.9vw;
    border-top: 5vw solid; 
  }
.digi-skill::after {
    top: -4.9vw;
    border-bottom: 5vw solid; 
  }

.dl-cos { background: #3cc6f3; }
.dl-cos::before { border-top-color: #3cc6f3; }
.dl-cos::after { border-bottom-color: #3cc6f3; }
.dl-cos .digi-skill-content {
      background: url(../images/dqindex/icons/child-online-safety-icon.png) top center no-repeat;
      background-size: 3.5vw;
  }

.dl-learn { background: #f8b143; }
.dl-learn::before { border-top-color: #f8b143; }
.dl-learn::after { border-bottom-color: #f8b143; }
.dl-learn .digi-skill-content {
    background: url(../images/dqindex/icons/learning-icon.png) top center no-repeat;
    background-size: 6.2vw;
}

.dl-or { background: #b7d334; }
.dl-or::before { border-top-color: #b7d334; }
.dl-or::after { border-bottom-color: #b7d334; }
.dl-or .digi-skill-content {
    background: url(../images/dqindex/icons/participation-icon.png) top center no-repeat;
    background-size: 4vw;
}

.dl-dit { background: #fff; color: #6e6f70; font-weight: bold; font-size: 1.6vw; opacity: 1; }
.dl-dit::before { border-top-color: #fff; }
.dl-dit::after { border-bottom-color: #fff; }
.dl-dit .digi-skill-content {
    background: url(../images/dqindex/dqindex-logo.png) center 1.5vw no-repeat;
    background-size: 9.5vw;
}

.dl-wi { background: #6a8ec9; }
.dl-wi::before { border-top-color: #6a8ec9; }
.dl-wi::after { border-bottom-color: #6a8ec9; }
.dl-wi .digi-skill-content {
    background: url(../images/dqindex/icons/digital-infra-icon.png) top center no-repeat;
    background-size: 3.5vw;
}

.dl-wb { background: #c91c6e; }
.dl-wb::before { border-top-color: #c91c6e; }
.dl-wb::after { border-bottom-color: #c91c6e; }
.dl-wb .digi-skill-content {
    background: url(../images/dqindex/icons/wellbeing-icon.png) top center no-repeat;
    background-size: 4.4vw;
}

.dl-fe { background: #ea4e99; }
.dl-fe::before { border-top-color: #ea4e99; }
.dl-fe::after { border-bottom-color: #ea4e99; }
.dl-fe .digi-skill-content {
    background: url(../images/dqindex/icons/female-icon.png) top center no-repeat;
    background-size: 2.9vw;
}

@media only screen and (max-width: 768px) {
    .index-header {
        height: 60px;
        min-height: 8vh;
        height: auto;
    }
    .iheader-contnet {
        padding-right: 1vw;
    }
    .index-logo img {
        width: 100px;
    }
    .imenu-item {
        font-size: 2.5vw;
        text-align: center;
        padding: 0 2vw;
    }

    .imenu-language {
        font-size: 1.2rem;
        text-align: center;
        padding: 0 2vw;
    }
    .imenu-link {
        padding: 4px 5px;
    }
    .index-banner {
        margin-top: 58px;
    }
    .ibanner-heading {
        top: 38%;
    }
    .ihead-subtitle {
        font-size: 2.8vw;
    }
    .index-section-container {
        margin-top: 5vh;
        padding: 0 5vw;
    }
    .isection-title {
        font-size: 7vw;
    }
    .isection-content {
        font-size: 4.8vw;
    }
    .skills-tracker {
        padding: 9vw 0 5vw;
    }
    .digi-skills-row {
        padding: 4.3vw 0;
    }
    .digi-skill {
        height: 18vw;
        width: 28vw;
        margin: 0 1vw;
        font-size: 2.6vw;
        border-radius: 0.2vw;
    }
    .digi-skill-content {
        padding: 7vw 2.2vw 0;
        top: -.1vw;
    }
    .digi-skill-hover {
        top: 105%;
        right: 0;
        left: 0;
        padding: 8px;
        border-radius: 8px;
    }
    .digi-skill-hover img {
        width: 40px;
        margin: 0px 1px;
    }
    .digi-skill::before, .digi-skill::after {
        border-left: 14vw solid transparent;
        border-right: 14vw solid transparent; 
    }
    .digi-skill::before {
        bottom: -6.9vw;
        border-top-width: 7vw; 
    }
    .digi-skill::after {
        top: -6.9vw;
        border-bottom-width: 7vw; 
    }
    .dl-dit {
        font-size: 3.3vw;
    }

    .dl-cos .digi-skill-content { background-size: 6vw; }
    .dl-learn .digi-skill-content { background-size: 10vw; }
    .dl-or .digi-skill-content { background-size: 7vw; }
    .dl-dit .digi-skill-content { background-size: 18vw; }
    .dl-wi .digi-skill-content { background-size: 6vw; }
    .dl-wb .digi-skill-content { background-size: 7.5vw; }
    .dl-fe .digi-skill-content { background-size: 4.5vw; }

    .iactions-content {
        display: block;
    }
    .iaction-col {
        width: 100%;
    }
    .iaction-btn a {
        margin-top: 15px;
        margin-bottom: 50px;
        font-size: 1.1rem;
    }

}