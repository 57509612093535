.quiz-time-main-container {
    padding: 56px 20px;
    min-height: 100%;
    width: 100%;
}

.quiz-time-progress-container {
    position: relative;
    margin: 0 auto;
    background-color: #ddd;
    border-radius: 5px;
    width: 60vh;
    height: 3vh;
    margin-top: 3vh;
}

.quiz-time-progress-container p {
    position: absolute;
    left: 102%;
    margin: 0;
    font-size: 1.8em;
    line-height: 1em;
}

.quiz-time-progress-img {
    position: absolute;
    left: 0%;
    height: 7vh;
    top: -100%;
    /* z-index: 99; */
}

.quiz-time-progress-bar {
    position: absolute;
    height: 100%;
    width: 0%;
    border-radius: 5px;
}

.qt-question-num-total {
    color: #ddd;
}

.qt-guide-container {
    padding-top: 20px;
    min-height: 35vh;
    text-align: center;
}

.qt-guide-heading {
    font-size: 2.7rem;

    float: right;
    width: 50%;
    margin-top: 7%;
    text-align: left;
}

.qt-guide-img-container {
    float: left;
    width: 40%;
    margin-top: 5%;
    display: flex;
    justify-content: flex-end;
}

.qt-guide-img {
    height: 40vh;
    margin: 30px 20px;
}

.qt-guide-body {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Source Sans Pro", "Helvetica Neue";

    float: right;
    width: 50%;
    text-align: justify;
    padding-right: 20%;
    margin: 2vh 0 6vh;
}

.qt-cont-btn-container {
    display: flex;
    justify-content: flex-end;

    float: right;
    width: 51%;
    padding-right: 20%;
}

.qt-cont-btn-container button {
    border-style: solid !important;
}

.qt-tooltip {
    position: relative;
    font-weight: 900;
}
.qt-tooltip-content {
    position: absolute;
    padding: 1vh 2vh;
    margin-right: 2vh;
    border: 1px solid #fff;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #FFFFFF;
    min-width: 20vh;
    overflow: visible;
    z-index: 100;
    font-family: "Source Sans Pro", "Helvetica Neue";
}
.qt-question-container {
    position: relative;
    height: 35vh;
    overflow-y: scroll;
    text-align: center;
    display: flex;
    align-items: center;
    padding-top: 4vh;
    width: 80%;
    margin: 0 auto;
}

.qt-question-content {
    /* position: absolute;
    bottom: 0; */
    font-family: "Source Sans Pro", "Helvetica Neue";
    font-weight: bold;
    font-size: 1.9rem;
    padding: 0 20px;
    width: 100%;
}

.qt-font-6 { 
    font-size: 1.75rem; 
}

.qt-question-body {

}

.qt-question-tips {
    /* font-size: 1.6rem; */
    margin-top: 5px;
}

.qt-answer-container {
    position: fixed;
    left: 15%;
    bottom: 2%;
    /*background: #f5ecdf;*/
    height: 48%;
    width: 70%;
    padding-bottom: 4vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    justify-content: center;
    align-items: center;
    align-content: start;
    overflow-y: scroll;
    font-family: "Source Sans Pro", "Helvetica Neue";
}

.qt-answer-container:after{
    content: "";
    box-shadow: white 0px 30px 40px -2em inset;
}

.qt-answer-span {
    cursor: pointer;
    font-size: 1.1rem;
    margin: 20px 15px 0;
    padding: 10px;
    min-width: 20%;
    border-radius: .6rem;
    text-align: center;
    align-self: stretch;
}

.qt-maq.qt-opt-3 .qt-answer-span {
    width: 100%;
}

.qt-maq.qt-opt-4 .qt-answer-span {
    width: 45%;
    min-height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qt-maq.qt-opt-5 .qt-answer-span {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qt-maq.qt-opt-8 .qt-answer-span {
    display: flex;
    width: 40%;
    /*height: 8vh;*/
    align-items: center;
    justify-content: center;
}

.qt-mcq.qt-opt-3 .qt-answer-span, 
.qt-mcq.qt-opt-4 .qt-answer-span, 
.qt-maq.qt-opt-6 .qt-answer-span, 
.qt-maq.qt-opt-7 .qt-answer-span, 
.qt-maq.qt-opt-9 .qt-answer-span, 
.qt-maq.qt-opt-10 .qt-answer-span, 
.qt-maq.qt-opt-11 .qt-answer-span, 
.qt-maq.qt-opt-12 .qt-answer-span {
    width: 60%;
}

.qt-lsq {
    width: 80%;
    left: 10%;
}

.qt-tfq {
    display: flex;
    align-content: center;
}

.qt-ynq .qt-answer-span {
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qt-lsq .qt-answer-span {
    height: 8vh;
    width: 16%;
    min-width: 16%;
    margin: 20px 10px 0;
    flex-flow: wrap;
    display: flex;
    align-content: center;
    justify-content: center;
}

.qt-white-mask-top {
    position: fixed;
    top: 50%;
    height: 2.5vh;
    width: 100%;
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}


.qt-white-mask-bot {
    position: fixed;
    bottom: 1.95%;
    height: 4vh;
    width: 100%;
    background: -moz-linear-gradient(bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.qt-slider-container {
    width: 75vh;
}

.qt-slider-section {
    position: relative;
    margin: 0 auto;
    background-color: #ddd;
    border-radius: 5px;
    height: 5vh;
}

.qt-slider-img {
    position: absolute;
    left: 0%;
    height: 12vh;
    top: -115%;
}

.qt-slider-answers {
    display: inline-table;
    width: 100%;
}

.qt-slider-answer-span {
    display: table-cell;
    width: 33%;
    text-align: center;
    font-family: 'Lemon/Milk', 'Helvetica Neue', arial, sans-serif;
    font-size: 1.8em;
    padding: 2vh 0;
    color: #c7c7ce;
    cursor: pointer;
}


.qt-next-btn, .qt-prev-btn {
    position: fixed;
    width: 9vh;
    height: 18vh;
    bottom: 44vh;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.qt-next-btn {
    right: 0;
    border-top-left-radius: 9vh;
    border-bottom-left-radius: 9vh;
}

.qt-next-btn-disable {
    background-color: #c1beb9;
}

.qt-next-btn-img {
    position: absolute;
    left: 50%;
    height: 6vh;
}

.qt-prev-btn {
    left: 0;
    border-top-right-radius: 9vh;
    border-bottom-right-radius: 9vh;
}

.qt-prev-btn-img {
    position: absolute;
    left: 20%;
    height: 6vh;
}

.balloon_game_start_btn_container {
    bottom: 33%;
    position: fixed;
    text-align: center;
    width: 100%;
}

.balloon_game_start_btn {
    height: 10vh;
    width: 30vw;
    margin-left: 32vw;
    border-radius: 12px;
}

.balloon_game_start_btn_img {
    position: absolute;
    top: 22%;
    left: 45%;
    height: 6vh;
}


@media only screen and (max-width: 768px) {
    .quiz-time-progress-container {
        width: 80%;
    }

    .quiz-time-progress-container p {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        text-align: center;
        margin-top: 1vh;
        font-size: 1.2em;
    }

    .qt-question-container {
        width: 100%;
    }

    .qt-answer-container {
        left: 10%;
        width: 80%;
    }

    .qt-question-content {
        font-size: 1.3rem;
    }

    .qt-answer-span {
        margin: 14px 20px 0;
        min-width: 40%;
        width: 100%;
    }

    .qt-maq.qt-opt-4 .qt-answer-span, .qt-maq.qt-opt-5 .qt-answer-span, 
    .qt-maq.qt-opt-6 .qt-answer-span, .qt-maq.qt-opt-7 .qt-answer-span,
    .qt-maq.qt-opt-8 .qt-answer-span, .qt-maq.qt-opt-9 .qt-answer-span, 
    .qt-maq.qt-opt-10 .qt-answer-span, .qt-maq.qt-opt-11 .qt-answer-span, 
    .qt-maq.qt-opt-12 .qt-answer-span, .qt-mcq.qt-opt-4 .qt-answer-span,
    .qt-mcq.qt-opt-3 .qt-answer-span {
        width: 100%;
    }

    .qt-font-1 { font-size: 1.4rem; }
    .qt-font-2 { font-size: 1.3rem; }
    .qt-font-3 { font-size: 1.2rem; }
    .qt-font-4 { font-size: 1.1rem; }
    .qt-font-5, .qt-font-6 { font-size: 0.9rem; }
    .qt-font-7 { font-size: .8rem; }

    .qt-lsq .qt-answer-span {
        width: inherit;
        height: initial;
    }

    .qt-slider-answer-span {
        font-size: 1.2em;
    }

    .qt-next-btn, .qt-prev-btn {
        width: 10vh;
        height: 9vh;
        bottom: 0;
    }

    .qt-next-btn {
        border-top-left-radius: 100%;
        border-bottom-left-radius: 0;
    }

    .qt-prev-btn {
        border-top-right-radius: 100%;
        border-bottom-right-radius: 0;
    }

    .qt-next-btn-img {
        left: 55%;
        top: 30%;
        height: 5vh;
    }

    .qt-prev-btn-img {
        top: 30%;
        height: 5vh;
    }

    .qt-guide-img-container {
        /*min-height: 50vh;*/
    }

    .qt-guide-heading {
        font-size: 1.7rem;
    }

    .qt-guide-img-container, .qt-cont-btn-container {
        justify-content: center;
    }

    .qt-guide-img {
        height: 24vh;
        margin-bottom: 20px;
    }

    .qt-guide-body {
        font-size: 1.1rem;
        margin: 0 4vh 6vh;
    }

    .qt-guide-heading, .qt-guide-img-container, .qt-guide-body, .qt-cont-btn-container {
        float: unset;
        width: auto;
        margin-top: auto;
        padding-right: unset;
        text-align: center;
    }
    
}
